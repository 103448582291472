/**
LEGACY Short classes
 */

.ml-10 {
  margin-left: 10px !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100% !important;
}

.h-50-3 {
  height: calc(50% / 3) !important;
}

.h-50 {
  height: 50%;
}

.hidden {
  display: none !important;
}

.p-r {
  position: relative !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.c-p {
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.gap-4 {
  gap: 4px;
}

.gap-2 {
  gap: 2px;
}

.gap-8 {
  gap: 8px;
}

.gap-32 {
  gap: 32px;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.c-primary {
  color: var(--color-primary-500);;
}

//todo duplicated
.primary {
  color: var(--color-primary-500);;
}


.gap-24 {
  gap: 24px;
}

.gap-10 {
  gap: 10px;
}

.opacity-25 {
  opacity: 0.25;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.p-24 {
  padding: 24px !important;
}

.p-25 {
  padding: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-8 {
  padding: 8px !important;
}

//todo check duplicate
.mw-m {
  width: 100% !important;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

.c-yellow {
  color: #ffdd6e !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-8 {
  margin-bottom: 8px;
}


.p-r {
  position: relative !important;
}

.c-p {
  cursor: pointer;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.ph-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.ph-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}


.svg-fill-primary, .ui-icon-primary {
  .mat-icon svg path {
    fill: var(--color-primary-500);
  }

  span:before {
    color: var(--color-primary-500);
  }
}

.svg-fill-white, .ui-icon-white {
  .mat-icon svg path {
    fill: #FFFFFF;
  }

  span:before {
    color: var(--elevation-surface);
  }
}

.svg-fill-red, .ui-icon-red {
  .mat-icon svg path {
    fill: var(--color-red-500);
  }

  span:before {
    color: var(--color-red-500);;
  }
}

.svg-fill-yellow, .ui-icon-yellow {
  .mat-icon svg path {
    fill: #F59E0B;
  }

  span:before {
    color: #F59E0B;
  }
}

.svg-fill-green, .ui-icon-green {
  .mat-icon svg path {
    fill: var(--color-secondary-green-500);
  }

  span:before {
    color: var(--color-secondary-green-500);
  }
}


.svg-fill-gray {
  .mat-icon svg path {
    fill: #7a88b1;
  }
}

.svg-fill-gray-600 {
  .mat-icon svg path {
    fill: var(--color-secondary-gray-600);
  }
}


.icon-12 {
  .mat-icon {
    height: 12px !important;
    width: 12px !important;
    line-height: 12px !important;
    font-size: 12px !important;
  }
}

.icon-14 {
  .mat-icon {
    height: 14px !important;
    width: 14px !important;
    line-height: 14px !important;
    font-size: 14px !important;
  }
}

.icon-16 {
  .mat-icon {
    height: 16px !important;
    width: 16px !important;
    line-height: 16px !important;
    font-size: 16px !important;
  }
}

.icon-18 {
  .mat-icon {
    height: 18px !important;
    width: 18px !important;
    line-height: 18px !important;
    font-size: 18px !important;
  }
}

.icon-20 {
  .mat-icon {
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    font-size: 20px !important;
  }
}

.icon-24 {
  .mat-icon {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
    font-size: 24px !important;
  }
}

.child-100 {
  > * {
    width: 100%;
  }
}

.t-r {
  text-align: right !important;
}

.t-l {
  text-align: left !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.ml-4 {
  margin-left: 4px;
}

.pl-24 {
  padding-left: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.gap-5 {
  gap: 5px;
}

.z-index-1001 {
  z-index: 1001 !important;
}

.mt-48 {
  margin-top: 48px;
}

.gap-6 {
  gap: 6px;
}
